<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Whatsapp Marketing</h4>
            <div class="small text-muted">Envíos Masivos de mensajes a través de Whatsapp</div>
          </b-col>          
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="9">
          <b-row>
            <b-col lg="8">  
              <b-row>                      
                <b-col lg="6">
                  <Widget :configWidget="configWidget" reference="indicator_total_contacts" :hasAccess="configWidget.elements.indicatorTotalContacts" />
                </b-col>
                <b-col lg="6">
                  <Widget :configWidget="configWidget" reference="indicator_total_lists_contacts" :hasAccess="configWidget.elements.indicatorTotalListsContacts" />
                </b-col>                
                <b-col lg="12">
                  <Widget :configWidget="configWidget" reference="bar_messages_months" :hasAccess="configWidget.elements.barMessagesMonths" />            
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="4">         
              <Widget :configWidget="configWidget" reference="list_contacts_without_list" :hasAccess="configWidget.elements.listContactsWithoutList" />            
            </b-col>
          </b-row>
        </b-col>  
        <b-col lg="3">

          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCrudLists()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Listas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de listas de contactos
              </p>
            </b-list-group-item>
          </b-list-group>    

          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCrudContact()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Contacto</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de contactos
              </p>
            </b-list-group-item>
          </b-list-group>    
        
          <b-list-group class="mt-2">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCrudMessage()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Mensajes</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de mensajes
              </p>
            </b-list-group-item>
          </b-list-group>
        
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCrudConversations()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Conversaciones</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de conversaciones
              </p>
            </b-list-group-item>
          </b-list-group>

          <b-list-group class="mt-2">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCrudSettings()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Configuraciones</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Configuraciones generales
              </p>
            </b-list-group-item>
          </b-list-group>

          <hr v-if="arr.PHPCustom.length">
          <b-list-group class="mt-2" v-if="arr.PHPCustom.length">
            <b-list-group-item v-for="item in arr.PHPCustom" :key="item.id" href="#" class="flex-column align-items-start" @click="openPHPCustom(item)">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{item.name}}</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                {{item.observations}}
              </p>
            </b-list-group-item>
          </b-list-group>
        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'
  import ServicesPHP from '@/components/modules/users/php/staff/services'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.WHATSAPP_MARKETING,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.WHATSAPP_MARKETING,
          profile_id: Profiles.PERSONAL,
          elements: {             
            indicatorTotalContacts: true,
            indicatorTotalListsContacts: true,
            listContactsWithoutList: true,
            barMessagesMonths: true,
          }
        },
        primaryColor: '',
        arr: {
          PHPCustom: []
        }                     
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.indicatorTotalContacts = Helper.hasAccessWidget(this.configWidget, 'indicator_total_contacts')      
      this.configWidget.elements.indicatorTotalListsContacts = Helper.hasAccessWidget(this.configWidget, 'indicator_total_lists_contacts')      
      this.configWidget.elements.listContactsWithoutList = Helper.hasAccessWidget(this.configWidget, 'list_contacts_without_list')      
      this.configWidget.elements.barMessagesMonths = Helper.hasAccessWidget(this.configWidget, 'bar_messages_months')      
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
      this.getPHPCustom()
    },    
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      
      openCrudMessage() {
        this.$router.push({ name: 'WhatsappMarketingStaffCrudMessage' })
      },            

      openCrudLists() {
        this.$router.push({ name: 'WhatsappMarketingStaffCrudLists' })
      },            

      openCrudContact() {
        this.$router.push({ name: 'WhatsappMarketingStaffCrudContact' })
      }, 

      openCrudConversations() {
        this.$router.push({ name: 'WhatsappMarketingStaffCrudConversations' })
      },    
      
      openCrudSettings() {
        this.$router.push({ name: 'WhatsappMarketingStaffCrudSettings' })
      },

      getPHPCustom() {
        ServicesPHP.obtenerPHPByModule(Modules.WHATSAPP_MARKETING)
        .then((response) => {
          this.arr.PHPCustom = response.data       
        })
      },
      openPHPCustom(item) {
        this.$router.push({ name: 'ViewStaffViewPHPCustom', params: {id: item.id} })
      }      
    }    
   
  }
</script>

<style>

</style>
